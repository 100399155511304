/*================ Matrix highlight blocks ================*/

/**
 * Full width
 */
.highlight-block {
	@include full-width;
}

/**
 * Inner padding
 */
.highlight-block {
	@include clearfix;
	@include p-y-spacer-($spacer-lg);
	@include p-r-spacer-($spacer-md);
}

/**
 * Background color
 */
.highlight-block {
	background-color: $col--background-feature;
}

/**
 * Font sizes
 */
.highlight-block {
	@include type--feature;
}

/**
 * Format heading
 */
.highlight-block__heading {
	text-transform: uppercase;
}
