/*================ Matrix retreat location block ================*/

/**
 * Override clearing so blocks can float
 */
.retreat {
	clear: none;
}

/**
 * Vertical spacing below blocks
 */
.retreat {
	@include m-b-spacer-($spacer-md);
}

/**
 * Block bottom padding
 */
.highlight-block--retreats {
	@include feature-bottom-margin($spacer-lg);
}

/**
 * Remove space below name
 */
.retreat__name {
	@include m-b-spacer-($spacer-none);
}
