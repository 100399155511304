//-----------------------------------------------------------------------------
// Mixins specific to this website
//-----------------------------------------------------------------------------

// Full width mixin
@mixin full-width {
  margin-right: -$spacer-md;
  margin-left: -$spacer-md;

  @include media-query($medium) {
    margin-right: $spacer-none;
    margin-left: $spacer-none;
  }
}

// Calculate feature bottom margin
// Pass in value of margin below last child
@mixin feature-bottom-margin($val) {
  @include p-b-spacer-(calc(#{$spacer-lg} - #{$val}));
}
