/*================ Transitions ================*/

/**
 * Define initial load transition
 */
.new-session {
	.site-wrapper--home .site-inner-wrapper,
	.site-wrapper--main .site-header,
	.site-wrapper--main .content-wrapper {
		opacity: 0;
		animation-name: fadeIn;
		animation-fill-mode: forwards;
		animation-delay: 0.5s;
		animation-duration: 0.5s;
	}
}

/**
 * Define between pages transition
 */
.fade-in {
	.site-wrapper--home .site-inner-wrapper,
	.site-wrapper--main .content-wrapper {
		opacity: 0;
		animation-name: fadeIn;
		animation-fill-mode: forwards;
		animation-delay: 0.5s;
		animation-duration: 0.5s;
	}
}
