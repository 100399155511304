/*================ Videos entry ================*/

/**
 * Full width videos
 */
.videos-entry__video-wrapper {
  @include full-width;
}

/**
 * Vertical spacing
 */
.videos-entry__video-wrapper {
  @include m-b-spacer-($spacer-lg);
}
