/*================ Links ================*/

/**
 * Normal links with underlines
 */
a,
a:link,
a:visited,
a:active,
a:focus {
  color: $col--link-text;
  text-decoration: underline;
  text-decoration-color: $col--link-underline;
  @include transition--(text-decoration-color);
}

a:hover {
  color: $col--link-text-hover;
  text-decoration: underline;
  text-decoration-color: $col--link-underline-hover;
}

/**
 * Add click effect
 */
a {
  position: relative;
  top: 0;

  &:active {
    top: 0.0625rem;
  }
}
