/*================ Matrix blocks ================*/

/**
 * Vertical spacing
 * 1. Negative margin to match the page-title before
 */
.matrix-wrapper {
	margin-top: -1rem; /* 1 */

	@include media-query($large-up) {
		margin-top: -0.75rem; /* 1 */
	}
}

/**
 * Clear grid floats
 */
.matrix-block {
	clear: both;
}

/**
 * Format headings
 */
.heading-block__heading {
	text-transform: uppercase;
}

/**
 * Vertical spacing
 */
.matrix-block {
	@include m-t-spacer-($spacer-lg);
	@include m-b-spacer-($spacer-lg);
}
