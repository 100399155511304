/*================ Videos index ================*/

/**
 * Format video title
 */
.videos-index__video-name {
  @include m-b-spacer-($spacer-none);
  text-transform: uppercase;
}

/**
 * Full width videos
 */
.videos-index__video-wrapper {
  @include full-width;
}

/**
 * Vertical spacing between videos
 */
.videos-index__entry {
  @include m-y-spacer-($spacer-lg);
}

/**
 * Vertical spacing below introduction
 */
.videos-index__introduction {
  @include m-b-spacer-(-$spacer-lg);
}
