/*================ Pagination ================*/

/**
 * Background color
 */
.pagination {
  background-color: $col--background-pagination;
}

/**
 * Type size
 */
.pagination {
  @include type--pagination;
}

/**
 * Full width
 */
.pagination {
  @include full-width;
}

/**
 * Inner padding
 */
.pagination {
  @include p-x-spacer-($spacer-md);
  @include p-t-spacer-($spacer-lg);
  @include p-b-spacer-($spacer-md);

  @include media-query($large-up) {
    padding-bottom: 1rem;
  }
}

/**
 * Vertical spacing
 */
.pagination {
  @include m-y-spacer-($spacer-lg);
}
