/*================ Matrix video block ================*/

/**
 * Spacing
 */
.video-block {
  @include m-y-spacer-($spacer-lg);
}

/**
 * Full width
 */
.video-block {
  @include full-width;
}

/**
 * Format captions
 */
.video-block__caption {
  @include type--caption;
}

/**
 * Position captions
 */
.video-block__caption {
  @include m-t-spacer-($spacer-sm);
	@include m-x-spacer-($spacer-md);

	@include media-query($medium) {
		@include m-x-spacer-(0);
	}
}
