/*================ Hamburgers settings ================*/

$hamburger-padding-x                       : 0.5rem;
$hamburger-padding-y                       : 0.5rem;
$hamburger-layer-width                     : 1.5rem;
$hamburger-layer-height                    : 0.0625rem;
$hamburger-layer-spacing                   : 0.4375rem;
$hamburger-layer-color                     : #000;
$hamburger-layer-border-radius             : 0;
$hamburger-hover-opacity                   : 0.7;
$hamburger-hover-transition-duration       : 0.2s;
$hamburger-hover-transition-timing-function: ease;
