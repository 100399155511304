// -----------------------------------------------------------------------------
// This file defines the font sizes used within the design system.
// -----------------------------------------------------------------------------

// Font sizes
$fs-s0: 0.9375rem;  // 15px
$fs-s1: 1.125rem;   // 18px
$fs-s2: 1.25rem;  // 20px
$fs-s3: 1.5rem;     // 24px
$fs-s4: 2rem;       // 32px
$fs-s5: 2.25rem;    // 36px
$fs-s6: 4.5rem;     // 72px
$fs-s7: 9rem;       // 144px

@mixin size--0 {
  font-size: $fs-s0;
}

@mixin size--1 {
  font-size: $fs-s1;
}

@mixin size--2 {
  font-size: $fs-s2;
}

@mixin size--3 {
  font-size: $fs-s3;
}

@mixin size--4 {
  font-size: $fs-s4;
}

@mixin size--5 {
  font-size: $fs-s5;
}

@mixin size--6 {
  font-size: $fs-s6;
}

@mixin size--7 {
  font-size: $fs-s7;
}
