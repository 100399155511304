/*================ Homepage ================*/

/**
 * Nav menu formatting
 */
.site-nav--home {
	@include type--nav-homepage;
}

/**
 * Position nav menu items on larger screens
 */
.site-nav--home .site-nav__list-item {
	float: left;

	&.site-nav__list-item--new-line {
		clear: left;
	}
}

/**
 * Move arrows outside links
 */
.site-wrapper--home .site-nav__list-item a {

	&::before {
		content: '';
	}

	&:hover,
	&:active,
	&:focus {
		padding-left: 0;
	}
}
.site-wrapper--home .site-nav__list-item {
	padding-left: 1.1975rem;

	&::before {
		content: '→ ';
		position: relative;
		top: -0.0666666666666667em;
	}
}

/**
 * Add link underline state
 */
.site-wrapper--home .site-nav__list-item a {
	text-decoration-color: $col--home-nav-link-underline;
	@include transition--(text-decoration-color);

	&:hover,
	&:active,
	&:focus {
		text-decoration: underline;
		text-decoration-color: $col--home-nav-link-underline-hover;
	}
}



/**
 * Hide logo in footer
 */
.site-wrapper--home .site-footer .site-logo {
	display: none;
}

/**
 * Nudge gallery closer to logo
 */
.home-gallery {
	@include media-query($medium-down) {
		margin-top: -1.5rem;
	}
}

/**
 * Make gallery full width on mobile layout
 */
.home-gallery .owl-carousel {

	@include media-query($small) {
		@include full-width;
		width: auto !important;
	}

	@include media-query($medium) {
		margin-right: 0;
		margin-left: 0;
		width: 100vw;
		left: calc((100vw - 31.25rem) / 2 * -1);
	}
}

/**
 * Set height of gallery
 */
.home-gallery__image,
.home-gallery .owl-carousel,
.home-gallery .owl-stage-outer,
.home-gallery .owl-stage {
	height: 18.125rem;

	@include media-query($large-up) {
		height: 100vh;
	}
}

/**
 * Ensure images scale to a set height
 */
.home-gallery__image img {
	width: auto !important;
	height: 100%;
}

/**
 * Set cursors
 */
.home-gallery {
	cursor: all-scroll;

	body.is-loading & {
		cursor: default;
	}
}

.home-statements {
	cursor: default;
	user-select: none;
}

/**
 * Absolute position sections and set z-index
 */
.home-statements {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	z-index: 3;
}

.home-logo {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	z-index: 2;
}

@include media-query($large-up) {

	.site-wrapper--home {
		height: 100vh;
		width: 100vw;
		overflow: hidden;
	}

	.home-site-nav-wrapper {
		position: absolute;
		top: -0.625rem;
		left: $spacer-md;
		z-index: 4;
	}

	.home-gallery {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 0;
	}
}

/**
 * Prevent page scroll when page is loading
 */
body.is-loading {
	overflow: hidden;
}

/**
 * Show/hide statements
 */
.home-statements {
	display: none;

	&.is-active {
		display: block;
	}
}

/**
 * Statements formatting
 */
.home-statements {
	background-color: $col--background-statements;
	@include type--statement;
}

/**
 * Format individual slides
 */
.home-statements__statement-wrapper {
	background-color: $col--background-statements;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.home-statements__statement {
	@include vertical-center;
	@include p-x-spacer-($spacer-md);
}

/**
 * Start with first statement at front to avoid flash of last statement
 */
.home-statements__statement-wrapper:first-of-type {
	z-index: 5;
}

/**
 * Bring active statement to front
 */
.home-statements__statement-wrapper.is-active {
	z-index: 10;
}

/**
 * Format YWR logo
 */
.home-logo__acronym {
	@include type--home-logo;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

/**
 * Use logo instead of text
 */
.home-logo__acronym {
	background-image: url('../img/ywr.svg');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: 0 0.111111111111111em;
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
	display: block;
	height: 84px;
	width: 174px;

	@include media-query($large-up) {
		height: 144px;
		width: 348px;
	}
}

/**
 * YWR logo background show/hide
 */
.home-logo {
	transition: all 0.5s ease;
	background-color: $col--background-home-logo-loaded;
	pointer-events: none;

	body.is-loading & {
		background-color: $col--background-home-logo-loading;

		@include media-query($medium-down) {
			opacity: 1;
		}
	}

	@include media-query($medium-down) {
		opacity: 0;
	}

}
