/*================ Layout ================*/

/**
 * Outer margins
 */
.site-inner-wrapper {
  @include p-t-spacer-($spacer-md);
  @include p-x-spacer-($spacer-md);
}

/**
 * Make everything fill the height
 * (including absolutely positioned pseudo-element rulers)
 */
.site-wrapper {
  position: relative;
}

.site-inner-wrapper {
  position: relative;
  z-index: 2;
}

/**
 * Rulers
 */
.site-wrapper--main {
  @include media-query($large-up) {

    &::before,
    &::after {
      content: '';
      background-color: $col--ruler;
      height: 100%;
      width: 0.0625rem;
      position: absolute;
      top: 0;
      z-index: 0;
    }

    &::before {
      left: 16.66668%;
    }

    &::after {
      left: calc(41.6667% - 10px);
    }
  }
}

/**
 * Min-heights
 */
.site-wrapper--home {
  @include media-query($medium-down) {
    min-height: 100vh;
  }
}

.site-wrapper--main .content-wrapper {
  min-height: 40rem;
}

.site-wrapper--blank {
  min-height: 100vh;
}
