/*================ Matrix images block ================*/

/**
 * Full width
 */
.images-block__image {
	@include full-width;
}

/**
 * Gallery
 */
.images-block--gallery {
	@include full-width;
	z-index: 0;
	cursor: grab;

	.images-block__image {
		margin-top: 0;
		margin-right: 0;
		margin-left: 0;
	}
}
