/*================ Matrix gallery block ================*/

/**
 * Full width
 */
.gallery-block .owl-carousel {

	@include media-query($small) {
		@include m-l-spacer-(-$spacer-md);
		width: calc(100% + #{$spacer-lg});
	}

	@include media-query($medium) {
		@include m-l-spacer-($spacer-none);
		width: 100%;
	}

	@include media-query($large-up) {
		width: 101vw;
		position: relative;
		left: -42.8vw;
	}
}

/**
 * Position captions
 */
.gallery-block__caption-wrapper {
	@include m-t-spacer-($spacer-sm);
}

.gallery-block__caption {
	@include clearfix;
}

.gallery-block__counter {
	float: left;
	padding-right: 0.5em;
	max-width: 25%;
}

.gallery-block__caption-text {
	float: left;
	max-width: 75%;
}

.gallery-block__caption {
	p {
		@include m-y-spacer-(0);
	}
}

/**
 * Format captions
 */
.gallery-block__caption {
	@include type--caption;
}

.gallery-block__counter {
	color: $col--type-counter;
}

/**
 * Show and hide captions
 */
.gallery-block__caption {
	display: none;

	&.is-active {
		display: block;
	}
}

/**
 * Style cursor to show carousel is clickable
 */
.gallery-block .owl-carousel {
	cursor: grab;
}
