/*================ Page title ================*/

/**
 * Formatting
 */
.page-title {
  text-transform: uppercase;
}

/**
 * Vertical spacing
 */
.page-title {
  @include m-b-spacer-($spacer-none);
}
