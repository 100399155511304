/*================ Site navigation menu ================*/

/**
 * Type formatting
 */
.site-nav {
  @include type--nav-regular;
}

/**
 * List Formatting
 */
.site-nav__list {
  @include p-l-spacer-($spacer-none);
  list-style-type: none;
}

/**
 * Nav links
 */
.site-nav {

  a,
  a:link,
  a:visited {
    color: $col--nav-link-text;
    text-decoration: none;
    @include transition--(padding);

    &::before {
      content: '→ ';
      position: relative;
      top: -0.0666666666666667em;
      opacity: 0;
      @include transition--(opacity);
    }
  }

  a:hover,
  a:active,
  a:focus {
    color: $col--nav-link-text-hover;
    text-decoration: none;
    padding-left: 1.1975rem;

    &::before {
      opacity: 1;
    }
  }

  .site-nav__list {
    margin-left: -1rem;
  }
}

/**
 * Active state
 */
.site-nav__list-item.active a {
  padding-left: 1.1975rem;

  &::before {
    opacity: 1;
  }
}

/**
 * Visually align the top of the nav with logo
 */
.site-nav__large-up-nav {
  position: relative;
  top: -0.25rem;
}
