// -----------------------------------------------------------------------------
// This file sets the base styles using the design system.
// -----------------------------------------------------------------------------

/**
 * Base site colors
 */
body {
  color: $col--type-body;
}

/* Highlight colour only visible when page overscroll occurs */
html {
  background-color: $col--background-html;
}

/* Main site background-color */
.site-wrapper {
  background-color: $col--background-site-wrapper;
}

/**
 * Max width for tablet screens
 */
.site-inner-wrapper {
  @include media-query($medium-down) {
    max-width: $grid-medium;
    margin-right: auto;
    margin-left: auto;
  }
}

/**
 * Prevent horizontal scroll
 */
body {
  overflow-x: hidden;
}

/**
 * Base site typography
 * 1. Non-webfont to emulate FOUT before fonts are loaded
 * 2. Use webfont once it’s loaded
 * https://www.bramstein.com/writing/web-font-loading-patterns.html
 */
body {
  @include type--body;
  @include font--secondary; /* 1 */
}

.fonts-loaded body {
  @include font--primary; /* 2 */
}

/**
 * Set spacing for typographic elements
 */
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
blockquote,
table {
  @include m-y-spacer-($spacer-lh);

  &:first-of-type {
    @include m-t-spacer-($spacer-none);
  }
}

/**
 * Prevent bold text from being double bolded
 */
strong,
b {
  font-weight: inherit;
}

/**
 * Prevent italic text from being double italicised
 */
em,
i {
  font-style: normal;
}


/**
 * User selected text
 */
::selection {
  background: $col--selection-bg;
  color: $col--selection-text
}

/**
 * Change base font-size for small and large screens
 * This makes all elements styled with rems increase proportionally
 * 1. Standard, 16px base. Body text is set to 14px from here.
 * 2. This increases our body text style to 18px instead.
 */
html {
 font-size: 100%; /* 1. */
}

@include media-query($widescreen) {
  html {
    font-size: 128.571429%; /* 2. */
  }
}
