// -----------------------------------------------------------------------------
// This file defines the fonts used within the type styles.
// -----------------------------------------------------------------------------

// 1. Non-webfont to emulate FOUT before fonts are loaded
// 2. Use webfont once it’s loaded
// https://www.bramstein.com/writing/web-font-loading-patterns.html

@mixin font--primary {

  @at-root .fonts-loaded & {
    font-family: 'Tenor Sans', sans-serif; /* 2 */
  }
}

@mixin font--secondary {
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  // Web-safe font-stack so no webfont loading
}
