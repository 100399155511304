/*================ Logo ================*/

/**
 * Formatting
 */
.site-logo {
  @include type--logo;
}

/**
 * Link
 */
.site-logo__link {

  &,
  &:link,
  &:visited,
  &:hover,
  &:active,
  &:focus {
    color: $col--link-text;
    text-decoration: none;
  }
}

/**
 * Use logo instead of text
 */
.site-logo__link {
  background-image: url('../img/ywr.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 0.111111111111111em;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  height: 2.3125rem;
  width: 4.8125rem;

  @include media-query($large-up) {
    height: 2.625rem;
    width: 5.4375rem;
  }
}

/**
 * Spacing
 */
.site-logo {
  @include m-b-spacer-($spacer-lg);
}
