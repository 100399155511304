/*================ Matrix workshop block ================*/

/**
 * Override clearing so blocks can float
 */
.workshop {
	clear: none;
}

/**
 * Vertical spacing below blocks
 */
.workshop {
	@include m-b-spacer-($spacer-lg);
}

/**
 * Block bottom padding
 */
.highlight-block--workshops {
	@include feature-bottom-margin($spacer-lg);
}

/**
 * Format name
 */
.workshop__name {
	text-transform: uppercase;
}

/**
 * Font sizes
 */
.workshop__details {
	@include type--feature-small;
}

/**
 * Remove space below name
 */
.workshop__name {
	@include m-b-spacer-($spacer-none);
}
