// -----------------------------------------------------------------------------
// This file defines the type styles used within the design system.
// -----------------------------------------------------------------------------

// Body
@mixin type--body {
  @include font--primary;
  @include size--1;
  @include line-height--1;

  @include media-query($large-up) {
    @include size--2;
  }
}

// Feature
@mixin type--feature {
  @include font--primary;
  @include size--0;
  @include line-height--1;

  @include media-query($large-up) {
    @include size--1;
  }
}

// Feature small
@mixin type--feature-small {
  @include font--primary;
  @include size--0;
  @include line-height--1;
}

// Caption
@mixin type--caption {
  @include font--primary;
  @include size--0;
  @include line-height--1;
}

// Nav regular
@mixin type--nav-regular {
  @include font--secondary;
  @include size--0;
  @include line-height--3;
  text-transform: uppercase;
}

// Nav menu
@mixin type--nav-menu {
  @include font--secondary;
  @include size--1;
  @include line-height--3;
  text-transform: uppercase;
}

// Nav homepage
@mixin type--nav-homepage {
  @include font--secondary;
  @include size--3;
  @include line-height--2;
  text-transform: uppercase;
}

// Logo
@mixin type--logo {
  @include font--primary;
  @include size--4;
  @include line-height--0;
  text-transform: uppercase;

  @include media-query($large-up) {
    @include size--5;
  }
}

// Statement
@mixin type--statement {
  @include font--primary;
  @include size--5;
  @include line-height--0;
  text-align: center;
  text-transform: uppercase;

  @include media-query($large-up) {
    @include size--6;
  }
}

// Statement acronym
@mixin type--home-logo {
  @include font--primary;
  @include size--6;
  @include line-height--0;
  text-align: center;
  text-transform: uppercase;

  @include media-query($large-up) {
    @include size--7;
  }
}

// Footer
@mixin type--footer {
  @include font--primary;
  @include size--0;
  @include line-height--1;
}

// Pagination
@mixin type--pagination {
  @include font--primary;
  @include size--0;
  @include line-height--1;

  @include media-query($large-up) {
    @include size--1;
  }
}
