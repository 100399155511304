/*================ Header ================*/

/**
 * Vertical spacing
 */
.site-header__medium-down-nav {
  @include p-b-spacer-($spacer-md);
}

/**
 * Show/hide mobile menu
 */
.site-header__medium-down-nav-wrapper {
  height: 0;
  overflow: hidden;
}

.site-header__medium-down-nav {
  @include transition--(opacity);
  opacity: 0;

  &.is-active {
    opacity: 1;
  }
}

/**
 * Position hamburger
 */
 .site-header__medium-down {
   position: relative;
 }

.site-header__hamburger-wrapper {
  position: absolute;
  top: 0;
  right: -0.5rem;
}

/**
 * Hamburger colors */
.hamburger-inner,
.hamburger-inner:after,
.hamburger-inner:before {
  background-color: $col--hamburger;
}
