// -----------------------------------------------------------------------------
// This file defines the line heights used within the design system.
// -----------------------------------------------------------------------------

// Line heights
$lh-h0: 1;
$lh-h1: 1.333333333333333;
$lh-h2: 1.5;
$lh-h3: 2;

@mixin line-height--0 {
  line-height: $lh-h0;
}

@mixin line-height--1 {
  line-height: $lh-h1;
}

@mixin line-height--2 {
  line-height: $lh-h2;
}

@mixin line-height--3 {
  line-height: $lh-h3;
}
