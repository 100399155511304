/*================ Matrix studio location block ================*/

/**
 * Override clearing so blocks can float
 */
.studio-location {
	clear: none;

	&:nth-child(2n+1) {
		clear: both;
	}
}

/**
 * Vertical spacing below blocks
 */
.studio-location {
	@include m-b-spacer-($spacer-lg);
}

/**
 * Block bottom padding
 */
.highlight-block--studio-locations {
	@include feature-bottom-margin($spacer-lg);
}

/**
 * Format name
 */
.studio-location__name {
	text-transform: uppercase;
}

/**
 * Remove space below name
 */
.studio-location__name {
	@include m-b-spacer-($spacer-none);
}

/**
 * Font sizes
 */
.studio-location__classes {
	@include type--feature-small;
}

.studio-location__name,
.studio-location__location {
	@include media-query($medium-down) {
		@include size--1;
	}
}
