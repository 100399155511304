// -----------------------------------------------------------------------------
// This file defines the colors used within the design system.
// -----------------------------------------------------------------------------

// Core variables (color-focused)
$col--black: rgb(51, 51, 51);
$col--white: rgb(255, 255, 255);
$col--grey: rgb(155, 155, 155);
$col--transparent: transparent;
$col--cream: rgb(251, 248, 240);
$col--cream-translucent: rgba(251, 248, 240, 0.5);
