/*================ Site footer ================*/

/**
 * Vertical spacing
 */
.site-footer {
  @include m-t-spacer-($spacer-md);
  @include p-b-spacer-($spacer-lg);

  @include media-query($large-up) {
    @include m-t-spacer-($spacer-xl);
  }
}

/**
 * Inner spacing
 */
.site-footer__nav {
  @include media-query($medium-down) {
    @include m-b-spacer-($spacer-lg);
  }
}

/**
 * Formatting
 */
.site-footer__contact,
.site-footer__credit {
  @include type--footer;
}
